<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <loader />
  </v-container>
  <v-container v-else class="d-flex flex-column pa-8">
    <div class="header-top">
      <h2 class="view-title">Change Password</h2>
    </div>
    <v-card>
      <v-card-text>
        <v-form ref="form" class="form">
          <h2 class="user-name">
            {{ user.profile.first_name ? user.name : '' + ' ' + user.profile.last_name ? user.last_name : '' }}
          </h2>

          <v-text-field
            v-model="new_password"
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            label="New Password"
            hide-details="auto"
            outlined
            placeholder="New Password"
            hint="New Password"
            :rules="[validators.required, validators.passwordValidator]"
            @click:append="isPasswordVisible = !isPasswordVisible"
          />

          <!-- confirm password -->
          <v-text-field
            v-model="confirm_password"
            :type="isCPasswordVisible ? 'text' : 'password'"
            :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            label="Confirm New Password"
            hide-details="auto"
            outlined
            class="mt-3"
            @click:append="isCPasswordVisible = !isCPasswordVisible"
            :rules="[validators.required, checkConfirmPassword]"
          ></v-text-field>

          <v-alert v-if="errors && errors.length > 0" class="mt-4" color="error" text>
            <p class="font-weight-semibold mb-1">Messages</p>
            <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
              {{ error }}
            </p>
          </v-alert>

          <v-btn color="primary" class="btn-submit" @click="submit" :disabled="loading">
            <span v-if="loading_save === false">Save Changes</span>
            <span v-else>
              <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
              <span>Sending...</span>
            </span>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import { required, emailValidator, passwordValidator, alphaValidator, checked } from '@core/utils/validation'
import Loader from '@/components/spinner/Loader.vue'
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline, mdiLockOpen } from '@mdi/js'

export default {
  components: {
    Loader,
  },
  data() {
    return {
      loading: true,
      loading_save: false,
      user: false,
      errors: [],
      isCPasswordVisible: false,
      isPasswordVisible: false,

      new_password: '',
      confirm_password: '',

      validators: {
        required,
        emailValidator,
        passwordValidator,
        alphaValidator,
        checked,
      },

      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiLockOpen,
      },
    }
  },
  created() {
    this.getData()
  },
  computed: {
    checkConfirmPassword() {
      let check = false

      if (this.new_password === this.confirm_password) {
        check = true
      }

      return check || 'Password does not match'
    },
  },
  methods: {
    ...mapActions({
      getUserDetail: 'client/getUserDetail',
      setSnackbar: 'snackbar/set',
      changePassword: 'user/changePasswordAccount',
    }),
    async getData() {
      this.loading = true
      await this.getUserDetail(this.$route.params.user_id)
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.user) {
              this.user = resp.data.user
            }
          }
        })
        .catch(error => {
          this.errors = error.response.data.errors
        })
        .finally(() => {
          this.loading = false
        })
    },
    async submit() {
      const data = {
        user_id: this.user.id,
        new_password: this.new_password,
        password_confirmation: this.confirm_password,
      }

      const isFormValid = this.$refs.form.validate()
      if (!isFormValid) {
        this.loading_save = false
        return
      }

      this.loading_save = true
      await this.changePassword(data)
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              this.setSnackbar({
                status: true,
                text: 'The application was sent successfully.',
                color: 'primary',
              })

              this.errors = null
              this.$refs.form.reset()
            }
          }
        })
        .catch(error => {
          this.errors = error.response.data.errors
        })
        .finally(() => {
          this.loading_save = false
        })
    },
  },
}
</script>

<style scoped>
.user-name {
  margin-bottom: 20px;
}
.header-top {
  margin-bottom: 20px;
}
.btn-more {
  padding: 0px !important;
  word-break: break-word !important;
}
.btn-submit {
  margin-top: 20px;
}
</style>
